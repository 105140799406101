var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoading)?_c('div',[_c('OtherLoading')],1):_c('div',[_c('HeaderPanel',{attrs:{"title":"PO - Purchase Order","hasDropdown":false,"filter":_vm.filter,"placeholder":"Search PO Number","hasFilter":false,"routerPath":"/purchase-order/detail/0"},on:{"search":_vm.handleSearch}}),_c('div',{staticClass:"bg-white border-red p-2 p-lg-3 mt-3"},[_c('b-tabs',{attrs:{"content-class":""},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},_vm._l((_vm.statusList),function(status){return _c('b-tab',{key:status.id,attrs:{"value":status.id},on:{"click":function($event){return _vm.onTabChange(status.id, $event)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(status.name)+" ("+_vm._s(status.count)+") ")]},proxy:true}],null,true)},[_c('b-row',[_c('b-col',[_c('b-table',{attrs:{"responsive":"","striped":"","hover":"","fields":_vm.fields,"items":_vm.items,"busy":_vm.isBusy,"show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"cell(po_no)",fn:function({ item }){return [_c('router-link',{attrs:{"to":'/purchase-order/detail/' + item.po_id}},[_c('span',{staticClass:"underline"},[_vm._v(_vm._s(item.po_no))])])]}},{key:"cell(total_product)",fn:function({ item }){return [_c('div',[_c('span',{class:_vm.textQuantityClass(
                          item.status,
                          item.total_received_product,
                          item.total_product
                        )},[_vm._v(" "+_vm._s(item.total_received_product))]),_vm._v(" / "+_vm._s(item.total_product)+" ")])]}},{key:"cell(total_received_quantity)",fn:function({ item }){return [_c('div',[_c('span',{class:_vm.textQuantityClass(
                          item.status,
                          item.total_received_quantity,
                          item.total_quantity
                        )},[_vm._v(" "+_vm._s(item.total_received_quantity))]),_vm._v(" / "+_vm._s(item.total_quantity)+" ")])]}},{key:"cell(order_date)",fn:function({ item }){return [_c('div',[_vm._v(_vm._s(_vm._f("moment")(item.order_date,_vm.$formatDateNew)))])]}},{key:"cell(due_date)",fn:function({ item }){return [_c('div',[_vm._v(_vm._s(_vm._f("moment")(item.due_date,_vm.$formatDateNew)))])]}},{key:"cell(status)",fn:function({ item }){return [_c('div',{class:_vm.textStatusClass(item.status)},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-black my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',{staticClass:"ml-2"},[_vm._v("Loading...")])],1)]},proxy:true}],null,true)})],1)],1),_c('Pagination',{attrs:{"filter":_vm.filter,"rows":_vm.rows},on:{"handleChangeTake":_vm.handleChangeTake,"pagination":_vm.pagination}})],1)}),1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }